import styled from 'styled-components';
import Box from 'reusecore/src/elements/Box';

const VideoSectionWrapper = styled.section`
  padding: 9rem 0 0 0;
`;

export const VideoBox = styled(Box)`
  max-width: 100%;
  width: 700px;
  left: 0;
  right: 0;
  margin: auto;
`;

export const ButtonBox = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .ctaButton {
    margin: 0.5rem 1rem;
  }
`;

export default VideoSectionWrapper;
