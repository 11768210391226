import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import VideoJS from './VideoJS';

const ResponsiveVideoWrapper = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    video {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;

const StorageVideoEmbed = ({ embedId }) => {

    const playerRef = useRef(null);

    const videoJsOptions = { // lookup the options in the docs for more options
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: `https://storage.googleapis.com/images.clueify.com/demo-videos/${embedId}.mp4`,
            type: 'video/mp4'
        }]
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    if (embedId) {
        return (

            <ResponsiveVideoWrapper>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            </ResponsiveVideoWrapper>
        );
    } else {
        return null;
    }

};

StorageVideoEmbed.propTypes = {
    embedId: PropTypes.string.isRequired,
};

export default StorageVideoEmbed;