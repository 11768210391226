import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import YouTubeEmbed from '../../../components/YouTubeEmbed';
import StorageVideoEmbed from '../../../components/StorageVideoEmbed';
import VideoSectionWrapper, { VideoBox, ButtonBox } from './videoSection.style';
import CTAButton from '../../../components/CTAButton';

const VideoSection = ({
    sectionHeader,
    sectionTitle,
    sectionTitleContent,
    embedId,
    drive,
}) => {
    const handleClickDemoCall = () => {
        if (typeof window !== 'undefined') {
            if(typeof window.rdt === 'function') {
                window.rdt('track', 'Lead');
            }
            window.location.href = `https://calendly.com/clueify-adrian/30min`;
        }
    };

    return (
        <VideoSectionWrapper id="video_section">
            <Container>
                <Box {...sectionHeader}>
                    <Heading content={sectionTitleContent} {...sectionTitle} />
                </Box>
                <VideoBox className="row">
                    {drive ? (
                        <StorageVideoEmbed embedId={embedId} />
                    ) : (
                        <YouTubeEmbed embedId={embedId} />
                    )}
                </VideoBox>
                <ButtonBox>
                    <CTAButton
                        textID="general.test4Free"
                        fontSize="1rem"
                        height="2.5rem"
                    />
                    <CTAButton
                        colors="secondaryWithBg"
                        textID="general.bookDemo"
                        fontSize="1rem"
                        height="2.5rem"
                        onClick={handleClickDemoCall}
                    />
                </ButtonBox>
            </Container>
        </VideoSectionWrapper>
    );
};

VideoSection.propTypes = {
    sectionHeader: PropTypes.object,
    sectionTitle: PropTypes.object,
    sectionTitleContent: PropTypes.string,
    embedId: PropTypes.string,
    drive: PropTypes.bool,
};

VideoSection.defaultProps = {
    drive: false,
    sectionHeader: {
        mb: '56px',
    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: ['22px', '27px'],
        fontWeight: '700',
        color: '#081d34',
        mb: '0',
        lineHeight: '1.2',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        as: 'h1',
    },
};

export default VideoSection;
