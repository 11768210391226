import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';

const ResponsiveVideoWrapper = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;

const YoutubeEmbed = ({ embedId }) => {

    if (embedId) {
        return (
            <ResponsiveVideoWrapper>
                <iframe
                    src={`https://www.youtube.com/embed/${embedId}?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&color=white&theme=light&c&autohide=0" `}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </ResponsiveVideoWrapper>

        );
    } else {
        return null;
    }

};

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;