import React, { Fragment, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
  StyledCookieBanner,
} from '../containers/SaasModern/sassModern.style';
import Navbar from '../containers/SaasModern/Navbar';
import Footer from '../containers/SaasModern/Footer';
import VideoSection from '../containers/SaasModern/VideoSection';
import Seo from '../components/seo';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useQueryParam, StringParam } from 'use-query-params';

const PricingPage = () => {
  const intl = useIntl();
  const [navbarFixed, setNavbarFixed] = useState(false);
  const [id] = useQueryParam('id', StringParam);
  const [title] = useQueryParam('title', StringParam);

  const pageTitle = `${intl.formatMessage({ id: 'analysis.title' })} ${title}`;

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo lang={intl.locale} title={pageTitle} hideChat={true} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            onStateChange={status =>
              setNavbarFixed(status.status === Sticky.STATUS_FIXED)
            }
          >
            <DrawerProvider>
              <Navbar fixed={navbarFixed} />
            </DrawerProvider>
          </Sticky>
          <VideoSection
            sectionTitleContent={pageTitle}
            embedId={id}
            drive={true}
          />
          <Footer />
          <StyledCookieBanner
            onAccept={() => {}}
            onAcceptPreferences={() => {}}
            onAcceptStatistics={() => {}}
            onAcceptMarketing={() => {}}
            showPreferencesOption={false}
            showDeclineButton={true}
            necessaryOptionText={intl.formatMessage({
              id: 'landing.cookie_option1',
            })}
            statisticsOptionText={intl.formatMessage({
              id: 'landing.cookie_option2',
            })}
            marketingOptionText={intl.formatMessage({
              id: 'landing.cookie_option3',
            })}
            privacyPolicyLinkText={intl.formatMessage({
              id: 'landing.cookie_banner3',
            })}
            policyLink="/privacy-policy"
            acceptButtonText={intl.formatMessage({
              id: 'landing.cookie_accept_Button',
            })}
            declineButtonText={intl.formatMessage({
              id: 'landing.cookie_decline_Button',
            })}
          />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default PricingPage;
